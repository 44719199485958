import React from 'react';

import instagramLogo from 'assets/img/logos/instagram.png';
import facebookLogo from 'assets/img/logos/facebook.png';
import classes from './ContactPage.module.scss';


const ContactPage = (props) => {
  return (<div className={classes.page}>
    <iframe
      title='Contact Form'
      src="https://docs.google.com/forms/d/e/1FAIpQLSeR7StPyoKsxUD5CBY1Dx-AhMis6Eeq7_bwoDH9u22YXEwWFQ/viewform?embedded=true"
      width="100%"
      height="778"
      frameborder="0"
      marginheight="0"
      marginwidth="0">
        Loading...
      </iframe>

    <div className={classes.socialLogos}>
      <a href='https://www.instagram.com/atxmosaicworkshop/'><img src={instagramLogo} alt='Instagram' /></a>
      <a href='https://www.facebook.com/themosaicworkshop/'><img src={facebookLogo} alt='Facebook' /></a>
    </div>

    <div className={classes.map}>
      <iframe
        title='Map to Something Cool Studios'
        width="100%"
        height="450"
        frameborder="0"
        style={{border:0}}
        src="https://www.google.com/maps/embed/v1/place?q=2400%20E%20Cesar%20Chavez%20St%20%23206%2C%20austin%2C%20tx&key=AIzaSyB69uGXIi1_51g9BpICOUTyL4KjL09AeRg"
        allowfullscreen>
        </iframe>
      There is a parking lot available next to the veterinary clinic. Space in this lot is limited. Entrance is on Cesar Chavez. Street parking is available behind studio on 2nd Street.
    </div>

    <div className={classes.information}>
      <strong>Sept 7, 14, 21</strong><br />
      <ul>
        <li>8:00am - 11:00am outdoor installation</li>
        <li>12:00pm - 4:00pm indoor work</li>
      </ul>
      <strong>July 20 – Sept 28</strong><br />
      Saturdays 12:00pm – 4:00pm<br />
      Wednesdays 6:00pm – 9:00pm<br />
      Something Cool Studios, 2400 E Cesar Chavez St #206
    </div>

    <div className={classes.information}>
      Free!!<br />
      Open to the public, no experience necessary. All levels welcome.<br />
      Due to the nature of the materials, we ask all participants to be over 12 years of age.
    </div>

  </div>);
}

export default ContactPage;
