import React from 'react';
import classnames from 'classnames';

import ImageGallery from 'react-image-gallery';

import styles from './GalleryPage.module.scss';

const images = [
  '07A2E0E4-085D-4CDE-8597-1AB727B49F46.jpg',
  '0C7E01A4-EF89-4D6B-932B-7D0C674AA9D2.jpg',
  '1d741aa313f64415a5ede0fcf59bfc88.jpg',
  '396D980F-25BB-4CA4-B5FA-5B53E57D7DAC.jpg',
  '5F115002-5380-4E83-B058-9FA86CACAFDE.jpg',
  '686FBE94-82AF-436C-9509-8A0B9D5896D6.jpg',
  '6AEF4197-919E-4428-A097-4E7745DE7EEC.jpg',
  '8C7696D2-774D-438E-987E-3062478B5C56.jpg',
  '9A71AAA0-0549-46E3-98F6-E3EF8510E837.jpg',
  'IMG_0047.jpg',
  'IMG_0073.jpg',
  'IMG_0075.jpg',
  'IMG_0076.jpg',
  'IMG_0134.jpg',
  'IMG_0137.jpg',
  'IMG_0139.jpg',
  'IMG_0174.jpg',
  'IMG_0175.jpg',
  'IMG_0176.jpg',
  'IMG_0177.jpg',
  'IMG_0287.jpg',
  'IMG_0289.jpg',
  'IMG_0291.jpg',
  'IMG_0292.jpg',
  'IMG_0293.jpg',
  'IMG_0340.jpg',
  'IMG_0344.jpg',
  'IMG_0347.jpg',
  'IMG_0350.jpg',
  'IMG_0351.jpg',
  'IMG_0419.jpg',
  'IMG_0420.jpg',
  'IMG_0476.jpg',
  'IMG_0601.jpg',
  'IMG_0659.jpg',
  'IMG_0662.jpg',
  'IMG_0664.jpg',
  'IMG_0665.jpg',
  'IMG_0666.jpg',
  'IMG_0668.jpg',
  'IMG_0669.jpg',
  'IMG_0709.jpg',
  'IMG_0716.jpg',
  'IMG_0717(1).jpg',
  'IMG_0717.jpg',
  'IMG_0780.jpg',
  'IMG_0781.jpg',
  'IMG_0783.jpg',
  'IMG_0807.jpg',
  'IMG_0845.jpg',
  'IMG_0851.jpg',
  'IMG_0852.jpg',
  'IMG_0854.jpg',
  'IMG_0855.jpg',
  'IMG_0865.jpg',
  'IMG_0867.jpg',
  'IMG_0868.jpg',
  'IMG_0877.jpg',
  'IMG_0878.jpg',
  'IMG_0880.jpg',
  'IMG_0882.jpg',
  'IMG_0885.jpg',
  'IMG_0997.jpg',
  'IMG_1221.jpg',
  'IMG_1223.jpg',
  'IMG_1227.jpg',
  'IMG_1229.jpg',
  'IMG_1248.jpg',
  'IMG_1380.jpg',
  'IMG_1381.jpg',
  'IMG_1383.jpg',
  'IMG_1386.jpg',
  'IMG_1394.jpg',
  'IMG_1398.jpg',
  'IMG_1399.jpg',
  'IMG_1452.jpg',
  'IMG_1457.jpg',
  'IMG_1459.jpg',
  'IMG_9990.jpg',
  'IMG_9993.jpg',
];

const imageGallery = images.map(image => ({
  original: `/gallery/full/${image}`,
  thumbnail: `/gallery/thumb/${image}`,
}));

const GalleryPage = () => {
  return <div className={styles.holder}>
    <ImageGallery items={imageGallery} lazyLoad />
  </div>;
}

export default GalleryPage;
