import React, { useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './SchedulePage.module.scss';

const schedule = [
  {
    date: 'Saturday, July 20',
    description: 'Virtual tour of Austin. Meet the artists and the space. Start a small individual project.',
  },
  {
    date: 'Saturday, July 27',
    description: 'Meet the artists.  Get a brief history of mosaic art.  Complete and take home a small mosaic project.',
  },
  {
    date: 'Saturdays and Wednesdays in August',
    description: <span>Lecture on mosaic, art and culture. Fabrication of mosaic murals. (In studio workshop limited to 20 students, ages 12 and up.)</span>,
  },
  {
    date: 'Saturday and Wednesdays in September',
    description: 'On-site installation of mosaic murals.',
  },
  {
    date: 'Saturday, September 28',
    description: 'On-site unveiling celebration.  (All ages welcome)',
  },
];

const SchedulePage = (props) => {
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  return (<div className={classes.page}>
    <div className={classes.schedule}>
      {schedule.map((item, index) => <div
        key={item.date}
        className={classnames(classes.scheduleItem, {
          [classes.expanded]: index === selectedIndex,
        })}
      >
        <div className={classes.scheduleItemHeader} onClick={() => setSelectedIndex(index)}>
          <span className={classes.expandIcon}>
            <FontAwesomeIcon icon='chevron-up' />
          </span>
          {item.date}
        </div>
        <div className={classes.scheduleItemDescription}>{item.description}</div>
      </div>)}
      Please register <a href='https://www.facebook.com/events/490213951725421/' target='_blank' rel='noopener noreferrer'>here</a>
    </div>


    <div className={classes.information}>
      <strong>Sept 7, 14, 21</strong><br />
      <ul>
        <li>8:00am - 11:00am outdoor installation</li>
        <li>12:00pm - 4:00pm indoor work</li>
      </ul>
      <strong>July 20 – Sept 28</strong><br />
      Saturdays 12:00pm – 4:00pm<br />
      Wednesdays 6:00pm – 9:00pm<br />
      Something Cool Studios, 2400 E Cesar Chavez St #206
    </div>

    <div className={classes.information}>
      Free!!<br />
      Open to the public, no experience necessary. All levels welcome.<br />
      Due to the nature of the materials, we ask all participants to be over 12 years of age.
    </div>

  </div>);
}

export default SchedulePage;
