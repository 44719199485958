import React from 'react';

import classes from './AboutPage.module.scss';

const AboutPage = (props) => {
  return (<div className={classes.page}>
    <div className={classes.description}>
      J Muzacz, Carmen Rangel and Genevieve Levicki will hold weekly mosaic open studio workshops at Something Cool Studios, offered for free to the community to develop skills in community and public art mural design and mosaic fabrication and installation.  Workshops will be held from July 20th to September 28th, including an East Austin mosaic tour, and a mural unveiling reception celebration at the end of the program.
      <br /><br />
      With our diverse backgrounds, including a collection 10+ years teaching and working as entrepreneurs in creative fields, we as a team offer valuable skills and insights into art-making and creative expression for and with diverse communities, as well as hands-on experience community organizing, modeling a sustainable livelihood as professional creatives, and breaking down stereotypes through working together with a common, shared goal.
      <br /><br />
      This project is funded and supported in part by the City of Austin through the Cultural Arts Division believing an investment in the Arts is an investment in Austin’s future.  Visit Austin at <a href='http://NowPlayingAustin.com' target='_blank' rel="noopener noreferrer">NowPlayingAustin.com</a>.
      <br /><br />
      Any persons under the age of 18 must have a parent or guardian present to agree to necessary waivers. Parents do not have to stay during the workshop. They are however encouraged to also participate.
    </div>
    <div className={classes.information}>
      <strong>Sept 7, 14, 21</strong><br />
      <ul>
        <li>8:00am - 11:00am outdoor installation</li>
        <li>12:00pm - 4:00pm indoor work</li>
      </ul>
      <strong>July 20 – Sept 28</strong><br />
      Saturdays 12:00pm – 4:00pm<br />
      Wednesdays 6:00pm – 9:00pm<br />
      Something Cool Studios, 2400 E Cesar Chavez St #206
    </div>

    <div className={classes.information}>
      Free!!<br />
      Open to the public, no experience necessary. All levels welcome.<br />
      Due to the nature of the materials, we ask all participants to be over 12 years of age.
    </div>

  </div>);
}

export default AboutPage;
