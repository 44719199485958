import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

library.add(faBars);
library.add(faChevronUp);
library.add(faTimes);
