import React from 'react';

import classes from './Footer.module.scss';

import acaLogo from '../assets/img/logos/ACA.png';
import caoCadLogo from '../assets/img/logos/COA_CAD_logo.png';
import jmLogo from '../assets/img/logos/jm_logo.png';
import scsLogo from '../assets/img/logos/SCS_logo.png';

const Footer = (props) => {
  return (<div className={classes.footer}>
    <img src={acaLogo} alt='Austin Creative Alliance' />
    <img src={caoCadLogo} alt='Cultural Arts City of Austin Economic Development' />
    <img src={jmLogo} alt='J Muzacz' />
    <img src={scsLogo} alt='Something Cool Studios' />
  </div>);
}

export default Footer;
