import React from 'react';

import { HashRouter as Router, Route } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import HomePage from 'pages/HomePage';
import AboutPage from 'pages/AboutPage';
import ContactPage from 'pages/ContactPage';
import SchedulePage from 'pages/SchedulePage';
import GalleryPage from 'pages/GalleryPage';
import 'util/icons';

import classes from './App.module.scss';

function App() {
  return (
    <Router>
      <div className={classes.mainContainer}>
        <Header />
        <Route exact path='/' component={HomePage} />
        <Route path='/about' component={AboutPage} />
        <Route path='/schedule' component={SchedulePage} />
        <Route path='/contact' component={ContactPage} />
        <Route path='/gallery' component={GalleryPage} />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
