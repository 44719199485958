import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition } from 'react-transition-group';

import { MOBILE_MEDIA_QUERY } from 'util/constants';

import classes from './Header.module.scss';

const Header = (props) => {
  const [ isMenuOpen, setMenuOpen ] = useState(false);

  const closeMenu = () => setMenuOpen(false);

  return (
    <React.Fragment>
      <Media query={MOBILE_MEDIA_QUERY}>
        {matches => (
          <header className={classes.header}>
            <div className={classes.title}>Mosaic Workshop</div>
            {!matches && <div className={classes.desktopNavigation}>
              <div className={classes.navigationItem}>
                <Link
                  to='/'
                >
                  Home
                </Link>
              </div>
              <div className={classes.navigationItem}>
                <Link
                  to='/about'
                >
                  About
                </Link>
              </div>
              <div className={classes.navigationItem}>
                <Link
                  to='/schedule'
                >
                  Schedule
                </Link>
              </div>
              <div className={classes.navigationItem}>
                <Link
                  to='/contact'
                >
                  Contact
                </Link>
              </div>
              <div className={classes.navigationItem}>
                <Link
                  to='/gallery'
                >
                  Gallery
                </Link>
              </div>
            </div>}
            {matches && <span className={classes.menu} role='button' onClick={() => setMenuOpen(true)}>
              <FontAwesomeIcon icon='bars' />
            </span>}
            {matches && ReactDOM.createPortal(<CSSTransition
              in={isMenuOpen}
              timeout={300}
              classNames={{
                enter: classes.menuEnter,
                enterActive: classes.menuEnterActive,
                exit: classes.menuExit,
                exitActive: classes.menuExitActive,
              }}
              unmountOnExit
            >
              <div className={classes.navMenuDropdown}>
                <div className={classes.navMenuDropdownHeader}>
                  <div role='button' tabIndex='0' onClick={() => setMenuOpen(false)} className={classes.closeButton}>
                    <FontAwesomeIcon icon='times' />
                  </div>
                </div>
                <div className={classes.navMenuDropdownItems}>
                  <div className={classes.navMenuDropdownItem}>
                    <Link
                      to='/'
                      onClick={closeMenu}
                    >
                      Home
                    </Link>
                  </div>
                  <div className={classes.navMenuDropdownItem}>
                    <Link
                      to='/about'
                      onClick={closeMenu}
                    >
                      About
                    </Link>
                  </div>
                  <div className={classes.navMenuDropdownItem}>
                    <Link
                      to='/schedule'
                      onClick={closeMenu}
                    >
                      Schedule
                    </Link>
                  </div>
                  <div className={classes.navMenuDropdownItem}>
                    <Link
                      to='/contact'
                      onClick={closeMenu}
                    >
                      Contact
                    </Link>
                  </div>
                  <div className={classes.navMenuDropdownItem}>
                    <Link
                      to='/gallery'
                      onClick={closeMenu}
                    >
                      Gallery
                    </Link>
                  </div>
                </div>
              </div>
            </CSSTransition>, document.body
            )}
          </header>)
        }
      </Media>
      <div className={classes.donate}>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="89UDXPTWLU94J" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
    </React.Fragment>
  );
}

export default Header;
