import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import classes from './ImageCarousel.module.scss';

const ImageCarousel = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let timeout;
    const startTimer = () => {
      timeout = setTimeout(() => {
        setSelectedIndex((selectedIndex + 1) % images.length);
      }, 3 * 1000);
    };

    if (!timeout) {
      startTimer();
    }

    return () => {
      clearTimeout(timeout);
      timeout = null;
    };
  });

  return (<div className={classes.carousel}>
    {images.map((image, index) => {
      const style = {backgroundImage: `url(${image})`};

      return (
        <CSSTransition
          key={index}
          in={index === selectedIndex}
          timeout={666}
          classNames={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            enterDone: classes.enterDone,
            exit: classes.exit,
            exitActive: classes.exitActive,
            exitDone: classes.exitDone
          }}
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.image} style={style} />
        </CSSTransition>
      );
    })}
  </div>);
}

export default ImageCarousel;
