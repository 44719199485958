import React from 'react';

import ImageCarousel from 'components/ImageCarousel';
import classes from './HomePage.module.scss';

const images = [
  './img/desktop/01.jpg',
  './img/desktop/02.jpg',
  './img/desktop/03.jpg',
  './img/desktop/04.jpg',
  './img/desktop/05.jpg',
  './img/desktop/06.jpg',
  './img/desktop/07.jpg',
  './img/desktop/08.jpg',
  './img/desktop/09.jpg',
  './img/desktop/10.jpg',
  './img/desktop/11.jpg',
  './img/desktop/12.jpg',
  './img/desktop/13.jpg',
  './img/desktop/14.jpg',
  './img/desktop/15.jpg',
  './img/desktop/16.jpg',
  './img/desktop/17.jpg',
  './img/desktop/18.jpg',
  './img/desktop/19.jpg',
  './img/desktop/20.jpg',
  './img/desktop/21.jpg',
  './img/desktop/22.jpg',
  './img/desktop/23.jpg',
  './img/desktop/24.jpg',
  './img/desktop/25.jpg',
];

const HomePage = (props) => {
  return (<div className={classes.page}>
    <div className={classes.information}>
      <br />
      Come to the unveiling party for the Grey Ghost mural on Saturday, September 28, 2019, 4-8pm.  Click <a href='https://www.facebook.com/events/385313332397041/permalink/390114508583590/' target='_blank' rel="noopener noreferrer">here</a> for details.
      <br />
      <br />
      <strong>Sept 7, 14, 21</strong><br />
      <ul>
        <li>8:00am - 11:00am outdoor installation</li>
        <li>12:00pm - 4:00pm indoor work</li>
      </ul>
      <strong>July 20 – Sept 28</strong><br />
      Saturdays 12:00pm – 4:00pm<br />
      Wednesdays 6:00pm – 9:00pm<br />
      Something Cool Studios, 2400 E Cesar Chavez St #206
    </div>

    <div className={classes.information}>
      Free!!<br />
      Open to the public, no experience necessary. All levels welcome.<br />
      Due to the nature of the materials, we ask all participants to be over 12 years of age.
    </div>
    <div className={classes.carousel}>
      <ImageCarousel images={images} />
    </div>
  </div>);
}

export default HomePage;
